exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alveari-js": () => import("./../../../src/pages/alveari.js" /* webpackChunkName: "component---src-pages-alveari-js" */),
  "component---src-pages-azienda-js": () => import("./../../../src/pages/azienda.js" /* webpackChunkName: "component---src-pages-azienda-js" */),
  "component---src-pages-aziende-beko-ita-index-js": () => import("./../../../src/pages/aziende/beko_ita/index.js" /* webpackChunkName: "component---src-pages-aziende-beko-ita-index-js" */),
  "component---src-pages-aziende-e-distribuzione-index-js": () => import("./../../../src/pages/aziende/e-distribuzione/index.js" /* webpackChunkName: "component---src-pages-aziende-e-distribuzione-index-js" */),
  "component---src-pages-aziende-intertek-index-js": () => import("./../../../src/pages/aziende/intertek/index.js" /* webpackChunkName: "component---src-pages-aziende-intertek-index-js" */),
  "component---src-pages-aziende-openjobmetis-index-js": () => import("./../../../src/pages/aziende/openjobmetis/index.js" /* webpackChunkName: "component---src-pages-aziende-openjobmetis-index-js" */),
  "component---src-pages-aziende-template-aziende-js": () => import("./../../../src/pages/aziende/templateAziende.js" /* webpackChunkName: "component---src-pages-aziende-template-aziende-js" */),
  "component---src-pages-aziende-tigullio-index-js": () => import("./../../../src/pages/aziende/tigullio/index.js" /* webpackChunkName: "component---src-pages-aziende-tigullio-index-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bomboniera-js": () => import("./../../../src/pages/bomboniera.js" /* webpackChunkName: "component---src-pages-bomboniera-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-miele-js": () => import("./../../../src/pages/miele.js" /* webpackChunkName: "component---src-pages-miele-js" */),
  "component---src-pages-mission-js": () => import("./../../../src/pages/mission.js" /* webpackChunkName: "component---src-pages-mission-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-regala-js": () => import("./../../../src/pages/regala.js" /* webpackChunkName: "component---src-pages-regala-js" */),
  "component---src-pages-template-apiario-monitorato-js": () => import("./../../../src/pages/templateApiarioMonitorato.js" /* webpackChunkName: "component---src-pages-template-apiario-monitorato-js" */),
  "component---src-pages-template-apicoltore-js": () => import("./../../../src/pages/templateApicoltore.js" /* webpackChunkName: "component---src-pages-template-apicoltore-js" */),
  "component---src-pages-template-articolo-js": () => import("./../../../src/pages/templateArticolo.js" /* webpackChunkName: "component---src-pages-template-articolo-js" */),
  "component---src-pages-template-crowdfunding-js": () => import("./../../../src/pages/templateCrowdfunding.js" /* webpackChunkName: "component---src-pages-template-crowdfunding-js" */),
  "component---src-pages-template-miele-js": () => import("./../../../src/pages/templateMiele.js" /* webpackChunkName: "component---src-pages-template-miele-js" */),
  "component---src-pages-template-regioni-js": () => import("./../../../src/pages/templateRegioni.js" /* webpackChunkName: "component---src-pages-template-regioni-js" */),
  "component---src-pages-thank-azienda-js": () => import("./../../../src/pages/thank-azienda.js" /* webpackChunkName: "component---src-pages-thank-azienda-js" */),
  "component---src-pages-thank-privato-js": () => import("./../../../src/pages/thank-privato.js" /* webpackChunkName: "component---src-pages-thank-privato-js" */)
}

